import { reaction, runInAction } from "mobx";
import getAuthenticatedInstance from "../../services/authenticated";
import { IPersistedStore } from "../PersistedStore";

export interface ISyncedUIStore<T> extends IPersistedStore {
  key: string;
  instances: T[];

  load: () => Promise<object>;
  save: (data: object) => Promise<object>;
}

class SyncedUIStore<T> implements ISyncedUIStore<T> {
  key: string = "";
  isHydrated = false;
  instances: T[] = [];

  load = async () => {
    if (!this.key || this.key.length === 0) {
      return;
    }

    const response = await getAuthenticatedInstance().get(
      `/ui-settings/${this.key}/`,
    );

    runInAction(() => {
      if (response.data?.settings?.instances) {
        this.instances = response.data.settings.instances;
      } else {
        // TODO: Remove once all users have migrated to new UI settings
        this.instances =
          JSON.parse(
            localStorage.getItem(`raspautomation.UI.${this.key}`) || "{}",
          ).instances || [];
      }
    });
    this.isHydrated = true;

    reaction(
      () => this.instances,
      (instances, oldInstances) => {
        if (JSON.stringify(instances) === JSON.stringify(oldInstances)) {
          return;
        }
        this.save();
      },
    );

    return response.data;
  };

  save = async () => {
    const response = await getAuthenticatedInstance().patch(
      `/ui-settings/${this.key}/`,
      { settings: { instances: this.instances } },
    );
    return response.data;
  };
}

export default SyncedUIStore;
