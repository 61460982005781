import {
  // computed,
  action,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import SyncedUIStore, { ISyncedUIStore } from "./SyncedUIStore";

export interface ISingleRoomUIStore {
  uuid: string;
  hiddenTraits: string[];
  traitOrder: string[];
}

export interface IRoomUIStore extends ISyncedUIStore<ISingleRoomUIStore> {
  find: (uuid: string) => ISingleRoomUIStore;
  findIndex: (uuid: string) => number;
  update: (instance: ISingleRoomUIStore) => [ISingleRoomUIStore, boolean];
  setOrder: (uuids: string[]) => void;
  setTraitOrder: (uuid: string, newOrder: string[]) => void;
  hideTrait: (uuid: string, trait: string) => void;
  showTrait: (uuid: string, trait: string) => void;
}

class RoomUIStore
  extends SyncedUIStore<ISingleRoomUIStore>
  implements IRoomUIStore
{
  key: string = "room";

  constructor() {
    super();
    makeObservable(this, {
      isHydrated: observable,
      instances: observable,
      find: action,
      update: action,
      setOrder: action,
      setTraitOrder: action,
      hideTrait: action,
      showTrait: action,
    });

    this.load();
  }

  find = (uuid: string): ISingleRoomUIStore => {
    const instance = this.instances.find((room) => room.uuid === uuid);
    if (!instance) {
      const newInstance = {
        uuid: uuid,
        hiddenTraits: [],
        traitOrder: [],
      } as ISingleRoomUIStore;
      this.instances.push(newInstance);
      return newInstance;
    }
    return instance;
  };

  findIndex = (uuid: string): number =>
    this.instances.findIndex((instance) => instance.uuid === uuid);

  update = (instance: ISingleRoomUIStore): [ISingleRoomUIStore, boolean] => {
    const instanceIndex = this.findIndex(instance.uuid);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as ISingleRoomUIStore));
      return [instance as ISingleRoomUIStore, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = instance as ISingleRoomUIStore;
    });
    return [instance as ISingleRoomUIStore, false];
  };

  setOrder = (uuids: string[]) => {
    uuids.forEach((uuid) => {
      this.find(uuid);
    });
    runInAction(() => {
      this.instances.sort((a, b) =>
        uuids.indexOf(a.uuid) < uuids.indexOf(b.uuid) ? -1 : 1,
      );
    });
  };

  setTraitOrder = (uuid: string, newOrder: string[]) => {
    const instance = this.find(uuid);
    instance.traitOrder = newOrder;
    this.update(instance);
  };

  hideTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = [...instance.hiddenTraits, trait];
    this.update(instance);
  };

  showTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = instance.hiddenTraits.filter((t) => t !== trait);
    this.update(instance);
  };
}

const roomUIStore = new RoomUIStore();
export default roomUIStore;
