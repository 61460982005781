import { DateTime } from "luxon";
import { makeObservable, observable, reaction, runInAction } from "mobx";
import getAuthenticatedInstance from "../../../../services/authenticated";
import { fetchPrices, IPricePoint } from "../../../../services/plugin/Nordpool";
import { IPersistedStore } from "../../../PersistedStore";

export type TPriceSelection = "spot" | "energy" | "total";
export type TEnergyPrices = { [key: string]: IPricePoint[] };

export interface IEnergyPriceSettings {
  showEnergyPricesOnTop: boolean;
  priceSelection?: TPriceSelection;
  usePriceRange?: boolean;
  priceRange?: [number, number];
  showForecast?: boolean;
}

export interface INordpoolUIStore extends IPersistedStore {
  prices: TEnergyPrices;
  energyPriceSettings: IEnergyPriceSettings;
  fetchInProgress: boolean;
  load: () => Promise<void>;
  save: () => Promise<void>;
  setPriceSelection: (value: TPriceSelection) => void;
  toggleUsePriceRange: () => void;
  setPriceRange: (min: number, max: number) => void;
  toggleShowEnergyPricesOnTop: () => void;
  getUpcomingPrices: (
    building: string,
    forceUpdate: boolean,
  ) => Promise<IPricePoint[]>;
}

class NordpoolUIStore implements INordpoolUIStore {
  prices: TEnergyPrices = {};
  isHydrated = false;
  fetchInProgress = false;
  energyPriceSettings: IEnergyPriceSettings = {
    showEnergyPricesOnTop: false,
    priceSelection: "spot",
    usePriceRange: true,
    priceRange: [2, 10],
    showForecast: false,
  };

  constructor() {
    makeObservable(this, {
      isHydrated: observable,
      fetchInProgress: observable,
      energyPriceSettings: observable,
      prices: observable,
    });

    this.load();
  }

  load = async () => {
    const response = await getAuthenticatedInstance().get(
      `/ui-settings/plugin-nordpool/`,
    );

    runInAction(() => {
      if (response.data?.settings?.energyPriceSettings) {
        this.energyPriceSettings = response.data.settings.energyPriceSettings;
      } else {
        // TODO: Remove once all users have migrated to new UI settings
        this.energyPriceSettings =
          JSON.parse(localStorage.getItem(`raspautomation.UI.nordpool`) || "{}")
            .energyPriceSettings || {};
      }
    });
    this.isHydrated = true;

    reaction(
      () => this.energyPriceSettings,
      (energyPriceSettings, oldEnergenergyPriceSettings) => {
        if (
          JSON.stringify(energyPriceSettings) ===
          JSON.stringify(oldEnergenergyPriceSettings)
        ) {
          return;
        }
        this.save();
      },
    );

    return response.data;
  };

  save = async () => {
    const response = await getAuthenticatedInstance().patch(
      `/ui-settings/plugin-nordpool/`,
      { settings: { energyPriceSettings: this.energyPriceSettings } },
    );
    return response.data;
  };

  setPriceSelection = (value: TPriceSelection) => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        priceSelection: value,
      };
    });
  };

  toggleUsePriceRange = () => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        usePriceRange: !this.energyPriceSettings.usePriceRange,
      };
    });
  };

  setPriceRange = (min: number, max: number) => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        priceRange: [min, max],
      };
    });
  };

  toggleShowEnergyPricesOnTop = () => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        showEnergyPricesOnTop: !this.energyPriceSettings.showEnergyPricesOnTop,
      };
    });
  };

  toggleShowForecast = () => {
    runInAction(() => {
      this.energyPriceSettings = {
        ...this.energyPriceSettings,
        showForecast: !this.energyPriceSettings.showForecast,
      };
    });
  };

  getUpcomingPrices = async (building: string): Promise<IPricePoint[]> => {
    if (this.fetchInProgress) {
      throw "Fetching energy prices already in progress";
    }

    const startOfHour = DateTime.now().startOf("hour");
    const hoursToFetch = this.energyPriceSettings.showForecast ? 168 : 24;

    runInAction(() => {
      this.fetchInProgress = true;
    });
    const response = await fetchPrices({
      building_id: building,
      start_time: startOfHour.toISO(),
      end_time: startOfHour.plus({ hours: hoursToFetch }).toISO(),
      unit: "c/kWh",
    }).finally(() => {
      runInAction(() => {
        this.fetchInProgress = false;
      });
    });
    runInAction(() => {
      this.prices[building] = response.points;
    });
    return response.points;
  };
}

const nordpoolUiStore = new NordpoolUIStore();
export default nordpoolUiStore;
