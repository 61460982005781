import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "cronstrue/locales/fi";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import getEnvironmentConfig from "../environment/config";
import {
  IActionTrigger,
  IStateTrigger,
  ITimeTrigger,
  ITraitValueTrigger,
  IWebhookTrigger,
} from "../services/Rule";
import { IActionTrait } from "../services/Trait";
import stateStore from "../stores/StateStore";
import traitStore from "../stores/TraitStore";
import CronForm from "./CronForm";
import TranslatedTrait from "./TranslatedTrait";

export interface ITriggerCard {
  building: string;
  rule: string;
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger;
  onUpdate: (
    trigger:
      | IStateTrigger
      | ITraitValueTrigger
      | ITimeTrigger
      | IActionTrigger
      | IWebhookTrigger,
  ) => void;
  remove: () => void;
}

export interface IStateTriggerCard extends ITriggerCard {
  trigger: IStateTrigger;
}

export interface ITraitValueTriggerCard extends ITriggerCard {
  trigger: ITraitValueTrigger;
}

export interface ITimeTriggerCard extends ITriggerCard {
  trigger: ITimeTrigger;
}

export interface IActionTriggerCard extends ITriggerCard {
  trigger: IActionTrigger;
}

export interface IWebhookTriggerCard extends ITriggerCard {
  trigger: IWebhookTrigger;
}

const isStateTrigger = (
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger,
): trigger is IStateTrigger => {
  return trigger.trigger_class === "StateTrigger";
};

const isTraitValueTrigger = (
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger,
): trigger is ITraitValueTrigger => {
  return trigger.trigger_class === "TraitValueTrigger";
};

const isTimeTrigger = (
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger,
): trigger is ITimeTrigger => {
  return trigger.trigger_class === "TimeTrigger";
};

const isActionTrigger = (
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger,
): trigger is IActionTrigger => {
  return trigger.trigger_class === "ActionTrigger";
};

const isWebhookTrigger = (
  trigger:
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger,
): trigger is IWebhookTrigger => {
  return trigger.trigger_class === "WebhookTrigger";
};

const StateTriggerContent: FC<IStateTriggerCard> = (
  props: IStateTriggerCard,
) => {
  const { t } = useTranslation();
  const { building, trigger, onUpdate } = props;
  const { state: selectedStateUUID } = trigger;
  const states = stateStore.filter({ building_id: building });
  const selectedState = stateStore.find(selectedStateUUID) || states[0];

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{t("State")}</InputLabel>
        <Select
          variant="outlined"
          label={t("State")}
          value={selectedStateUUID || selectedState.uuid}
          renderValue={() => selectedState?.name || ""}
          onChange={(event) => {
            onUpdate({
              ...trigger,
              state: event.target.value,
            });
          }}
        >
          {states.map((state) => (
            <MenuItem key={state.uuid} value={state.uuid}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const TraitValueTriggerContent: FC<ITraitValueTriggerCard> = (
  props: ITraitValueTriggerCard,
) => {
  const { t } = useTranslation();
  const { building, trigger, onUpdate } = props;
  const { type, value } = trigger;
  const [selectedTraits, setSelectedTraits] = useState<string[]>(
    trigger.traits,
  );
  const traits = traitStore.filter({ building_id: building, sorted: true });
  const traitClasses = traitStore.getSortedTraitClasses(traits);
  const [traitClass, setTraitClass] = useState<string>(
    (selectedTraits.length > 0 &&
      traitStore.find(selectedTraits[0])?.trait_class) ||
      traitClasses[0],
  );
  const [tempValue, setTempValue] = useState(value);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{t("Class")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Class")}
          value={traitClass}
          renderValue={(x) => <TranslatedTrait traitClass={x} />}
          onChange={(event) => {
            if (event.target.value !== traitClass) {
              setSelectedTraits([]);
            }
            setTraitClass(event.target.value);
          }}
        >
          {traitClasses.map((traitclass) => (
            <MenuItem key={traitclass} value={traitclass}>
              <TranslatedTrait traitClass={traitclass} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Devices")}</InputLabel>
        <Select
          label={t("Devices")}
          multiple
          value={selectedTraits}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const selected =
              typeof event.target.value === "string"
                ? (event.target.value as string).split(",")
                : event.target.value;
            setSelectedTraits(selected);
          }}
          onClose={() => {
            onUpdate({
              ...trigger,
              traits: selectedTraits,
            });
          }}
        >
          {traits
            .filter((trait) => trait.trait_class === traitClass)
            .map((trait) => (
              <MenuItem key={trait.uuid} value={trait.uuid}>
                <Checkbox
                  color="secondary"
                  checked={selectedTraits.indexOf(trait.uuid) > -1}
                />
                {traitStore.getTraitText(trait.uuid)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Type")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Type")}
          value={type}
          renderValue={(x) => (x === 0 ? t("value under") : t("value over"))}
          onChange={(event) => {
            onUpdate({
              ...trigger,
              type: parseInt(event.target.value.toString(), 10) === 1 ? 1 : 0,
            });
          }}
        >
          <MenuItem value={0}>{t("value under")}</MenuItem>
          <MenuItem value={1}>{t("value over")}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        type="number"
        label={t("Value")}
        value={tempValue.toString()}
        onChange={(event) => {
          setTempValue(parseFloat(event.target.value));
        }}
        onBlur={() => {
          if (Number.isFinite(tempValue)) {
            onUpdate({
              ...trigger,
              value: tempValue,
            });
          }
        }}
      />
    </>
  );
};

const TimeTriggerContent: FC<ITimeTriggerCard> = (props: ITimeTriggerCard) => {
  const { trigger, onUpdate } = props;
  const [cronString, setCronString] = useState<string>(trigger.cron);

  return (
    <CronForm
      cronString={cronString}
      onChange={(cronString: string, commit: boolean) => {
        if (commit) {
          onUpdate({
            ...trigger,
            cron: cronString,
          });
        } else {
          setCronString(cronString);
        }
      }}
    />
  );
};

const WebhookTriggerContent: FC<IWebhookTriggerCard> = (
  props: IWebhookTriggerCard,
) => {
  const { t } = useTranslation();
  const { trigger, rule } = props;

  const url = `${getEnvironmentConfig().backend}/rule/${rule}/run/${
    trigger.uuid
  }/`;
  const urlElement = !trigger.uuid ? (
    t("Webhook URL will be revealed once the Rule saved.")
  ) : (
    <Link href={url} target="_blank">
      {url}
    </Link>
  );
  return (
    <Stack direction="column" alignItems="center" spacing={1}>
      <Typography variant="caption">{urlElement}</Typography>
      <Typography textAlign="center" variant="body1">
        <Trans>
          Webhook trigger is used trigger Rule for example from external
          services. The Rule is triggered when the URL above is called with a
          GET or POST request. In addition parameter{" "}
          <pre style={{ display: "inline-block", margin: 0 }}>skip_delay=1</pre>{" "}
          can be added to the request to skip any delays set in Actions.
        </Trans>
      </Typography>
    </Stack>
  );
};

const ActionTriggerContent: FC<IActionTriggerCard> = (
  props: IActionTriggerCard,
) => {
  const { t } = useTranslation();
  const { building, trigger, onUpdate } = props;
  const [selectedTraits, setSelectedTraits] = useState<string[]>(
    trigger.traits,
  );
  const traits = traitStore.filter({
    building_id: building,
    trait_class: "Action",
    sorted: true,
  });
  const traitClass = "Action";
  const availableActions: string[] = traits
    .filter((trait) => selectedTraits.indexOf(trait.uuid) !== -1)
    .map((trait) => (trait as IActionTrait).actions.split(","))
    .reduce((a, b) => [...a, ...b], [])
    .filter(
      (value, index, self) => value.length > 0 && self.indexOf(value) === index,
    );
  const [selectedActions, setSelectedActions] = useState<string[]>(
    trigger.actions
      .trim()
      .split(",")
      .filter((x) => x !== "" && availableActions.indexOf(x) !== -1),
  );

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{t("Devices")}</InputLabel>
        <Select
          label={t("Devices")}
          multiple
          value={selectedTraits}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const selected =
              typeof event.target.value === "string"
                ? (event.target.value as string).split(",")
                : event.target.value;
            setSelectedTraits(selected);
          }}
          onClose={() => {
            onUpdate({
              ...trigger,
              traits: selectedTraits,
            });
          }}
        >
          {traits
            .filter((trait) => trait.trait_class === traitClass)
            .map((trait) => (
              <MenuItem key={trait.uuid} value={trait.uuid}>
                <Checkbox
                  color="secondary"
                  checked={selectedTraits.indexOf(trait.uuid) > -1}
                />
                {traitStore.getTraitText(trait.uuid)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Actions")}</InputLabel>
        <Select
          label={t("Actions")}
          multiple
          value={selectedActions}
          renderValue={(selected) => selected.join(", ")}
          onChange={(event) => {
            const selected =
              typeof event.target.value === "string"
                ? (event.target.value as string).split(",")
                : event.target.value;
            setSelectedActions(selected);
          }}
          onClose={() => {
            onUpdate({
              ...trigger,
              actions: selectedActions.join(",").trim(),
            });
          }}
        >
          {availableActions.map((action) => (
            <MenuItem key={action} value={action}>
              <Checkbox
                color="secondary"
                checked={selectedActions.indexOf(action) > -1}
              />
              {action}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const TriggerCard: FC<ITriggerCard> = (props: ITriggerCard) => {
  const { t } = useTranslation();
  const { building, rule, trigger, onUpdate, remove } = props;
  const [triggerClass, setTriggerClass] = useState<
    | "StateTrigger"
    | "TraitValueTrigger"
    | "TimeTrigger"
    | "ActionTrigger"
    | "WebhookTrigger"
  >(trigger.trigger_class);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} marginBottom={-1}>
          <FormControl fullWidth>
            <InputLabel>{t("Trigger class")}</InputLabel>
            <Select
              variant="outlined"
              label={t("Trigger class")}
              value={triggerClass}
              onChange={(event) => {
                const x = event.target.value;
                if (x === "StateTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    state: "",
                  });
                } else if (x === "TimeTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    cron: "* * * * *",
                  });
                } else if (x === "TraitValueTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    traits: [],
                    type: 0,
                    value: 0.0,
                  });
                } else if (x === "ActionTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    traits: [],
                    actions: "",
                  });
                } else if (x === "WebhookTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                  });
                }
              }}
            >
              <MenuItem value="StateTrigger">{t("StateTrigger")}</MenuItem>
              <MenuItem value="TraitValueTrigger">
                {t("TraitValueTrigger")}
              </MenuItem>
              <MenuItem value="TimeTrigger">{t("TimeTrigger")}</MenuItem>
              <MenuItem value="ActionTrigger">{t("ActionTrigger")}</MenuItem>
              <MenuItem value="WebhookTrigger">{t("WebhookTrigger")}</MenuItem>
            </Select>
          </FormControl>
          {isStateTrigger(trigger) && (
            <StateTriggerContent
              building={building}
              rule={rule}
              trigger={trigger}
              onUpdate={onUpdate}
              remove={remove}
            />
          )}
          {isTraitValueTrigger(trigger) && (
            <TraitValueTriggerContent
              building={building}
              rule={rule}
              trigger={trigger}
              onUpdate={onUpdate}
              remove={remove}
            />
          )}
          {isTimeTrigger(trigger) && (
            <TimeTriggerContent
              building={building}
              rule={rule}
              trigger={trigger}
              onUpdate={onUpdate}
              remove={remove}
            />
          )}
          {isActionTrigger(trigger) && (
            <ActionTriggerContent
              building={building}
              rule={rule}
              trigger={trigger}
              onUpdate={onUpdate}
              remove={remove}
            />
          )}
          {isWebhookTrigger(trigger) && (
            <WebhookTriggerContent
              building={building}
              rule={rule}
              trigger={trigger}
              onUpdate={onUpdate}
              remove={remove}
            />
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteForeverIcon />}
          onClick={() => remove()}
        >
          {t("Delete")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default TriggerCard;
