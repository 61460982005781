import { action, makeObservable, observable, runInAction } from "mobx";
import SyncedUIStore, { ISyncedUIStore } from "./SyncedUIStore";

export interface ISingleDeviceUIStore {
  uuid: string;
  hiddenTraits: string[];
  traitOrder: string[];
}

export interface IDeviceUIStore extends ISyncedUIStore<ISingleDeviceUIStore> {
  find: (uuid: string) => ISingleDeviceUIStore;
  findIndex: (uuid: string) => number;
  update: (instance: ISingleDeviceUIStore) => [ISingleDeviceUIStore, boolean];
  setOrder: (uuids: string[]) => void;
  setTraitOrder: (uuid: string, newOrder: string[]) => void;
  hideTrait: (uuid: string, trait: string) => void;
  showTrait: (uuid: string, trait: string) => void;
}

class DeviceUIStore
  extends SyncedUIStore<ISingleDeviceUIStore>
  implements IDeviceUIStore
{
  key: string = "device";

  constructor() {
    super();
    makeObservable(this, {
      isHydrated: observable,
      instances: observable,
      find: action,
      update: action,
      setTraitOrder: action,
      hideTrait: action,
      showTrait: action,
    });

    this.load();
  }

  find = (uuid: string): ISingleDeviceUIStore => {
    const instance = this.instances.find((device) => device.uuid === uuid);
    if (!instance) {
      const newInstance = {
        uuid: uuid,
        hiddenTraits: [],
        traitOrder: [],
      } as ISingleDeviceUIStore;
      this.instances.push(newInstance);
      return newInstance;
    }
    return instance;
  };

  findIndex = (uuid: string): number =>
    this.instances.findIndex((instance) => instance.uuid === uuid);

  update = (
    instance: ISingleDeviceUIStore,
  ): [ISingleDeviceUIStore, boolean] => {
    const instanceIndex = this.findIndex(instance.uuid);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as ISingleDeviceUIStore));
      return [instance as ISingleDeviceUIStore, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = instance as ISingleDeviceUIStore;
    });
    return [instance as ISingleDeviceUIStore, false];
  };

  setOrder = (uuids: string[]) => {
    runInAction(() => {
      this.instances.sort((a, b) =>
        uuids.indexOf(a.uuid) < uuids.indexOf(b.uuid) ? -1 : 1,
      );
    });
  };

  setTraitOrder = (uuid: string, newOrder: string[]) => {
    const instance = this.find(uuid);
    instance.traitOrder = newOrder;
    this.update(instance);
  };

  hideTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = [...instance.hiddenTraits, trait];
    this.update(instance);
  };

  showTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = instance.hiddenTraits.filter((t) => t !== trait);
    this.update(instance);
  };
}

const deviceUIStore = new DeviceUIStore();
export default deviceUIStore;
