import CachedIcon from "@mui/icons-material/Cached";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDraggable } from "react-use-draggable-scroll";
import { IPricePoint } from "../../../services/plugin/Nordpool";
import nordpoolUiStore from "../../../stores/UI/plugin/nordpool/NordpoolUIStore";
import { logger } from "../../../utils";

export interface IPricePointWithDateTime extends Omit<IPricePoint, "time"> {
  time: DateTime;
}

export interface IPricePointWithValue extends IPricePointWithDateTime {
  value: number;
}

export interface IPricePointWithHeat extends IPricePointWithValue {
  heat: string;
}

export interface IUpcomingPrices {
  building: string;
}

export interface IUpcomingPriceElement {
  price: IPricePointWithHeat;
  dayChange: boolean;
}

export interface IUpcomingPricesStack {
  prices: IPricePointWithValue[];
  updatePrices?: () => void;
}

const valueToHeat = (price: number, minPrice: number, maxPrice: number) => {
  // https://stackoverflow.com/a/39776893
  // https://stackoverflow.com/a/27263918
  // https://stackoverflow.com/a/17268489
  const scaledPrice =
    (Math.min(Math.max(price, minPrice), maxPrice) - minPrice) /
    (maxPrice - minPrice);
  const h = (1 - scaledPrice) * 120;
  return `hsl(${h}, 100%, 50%)`;
};

const UpcomingPriceElement: FC<IUpcomingPriceElement> = (
  props: IUpcomingPriceElement,
) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { price, dayChange } = props;
  const startTime = price.time;

  return (
    <Stack direction="row" sx={{ marginLeft: dayChange ? "0 !important" : 1 }}>
      {dayChange && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            "& .MuiDivider-wrapper": {
              display: "flex",
              flexDirection: "column",
              paddingY: 0.5,
              paddingX: 1,
            },
          }}
        >
          <Typography variant="caption" noWrap lineHeight={1}>
            {price.time.toFormat("cccc")}
          </Typography>
          <Typography variant="caption" noWrap lineHeight={1}>
            {price.time.toFormat("dd.MM.")}
          </Typography>
        </Divider>
      )}
      <Stack
        key={price.time.valueOf()}
        direction="column"
        alignItems="center"
        paddingLeft={1}
        paddingRight={1}
        paddingTop={1}
        paddingBottom={1}
        spacing={0.5}
        sx={{
          background:
            price.forecast === false
              ? theme.palette.primary.light
              : theme.palette.secondary.light,
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{ userSelect: "none" }}
        >
          <Box>
            <Box
              sx={{
                width: 18,
                height: 18,
                borderRadius: 18 / 2,
                backgroundColor: price.heat,
              }}
            />
          </Box>
          {price.forecast === "day" ? (
            <Typography
              fontSize={13}
              lineHeight={1}
              paddingTop={0.5}
              textAlign="center"
              noWrap
            >
              {t("daily average")}
            </Typography>
          ) : (
            <Typography
              fontSize={13}
              lineHeight={1}
              paddingTop={0.5}
              textAlign="center"
            >
              {startTime.toFormat("HH")}-
              {startTime.plus({ hours: 1 }).toFormat("HH")}
            </Typography>
          )}
        </Stack>
        <Typography fontSize={13} lineHeight={1} paddingTop={0.5}>
          {price.value.toFixed(3)}&nbsp;c/kWh
        </Typography>
      </Stack>
    </Stack>
  );
};

export const UpcomingPricesStack: FC<IUpcomingPricesStack> = (
  props: IUpcomingPricesStack,
) => {
  const { t } = useTranslation();
  const { prices, updatePrices } = props;
  const { fetchInProgress } = nordpoolUiStore;
  const { usePriceRange, priceRange, showForecast } =
    nordpoolUiStore.energyPriceSettings;
  const [minPrice, maxPrice] = usePriceRange
    ? priceRange || [2, 10]
    : [
        Math.min(...prices.map((x) => x.value)),
        Math.max(...prices.map((x) => x.value)),
      ];
  const draggableRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(draggableRef);

  return (
    <Paper sx={{ marginBottom: 2 }}>
      <Stack
        {...events}
        ref={draggableRef}
        direction="row"
        spacing={1}
        paddingLeft={1}
        paddingRight={1}
        paddingTop={1}
        paddingBottom={1}
        sx={{
          overflowX: "auto",
        }}
      >
        {prices
          .filter((x) => (showForecast ? true : x.forecast === false))
          .map((price, i) => (
            <UpcomingPriceElement
              key={price.time.valueOf()}
              dayChange={
                i === 0 ? false : price.time.day !== prices[i - 1].time.day
              }
              price={{
                ...price,
                heat: valueToHeat(price.value, minPrice, maxPrice),
              }}
            />
          ))}
        {updatePrices !== undefined && (
          <Button
            variant="contained"
            startIcon={<CachedIcon />}
            onClick={() => updatePrices()}
            disabled={fetchInProgress}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              textWrap: "nowrap",
              minWidth: 200,
            }}
          >
            {t("Force update")}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

const UpcomingPrices: FC<IUpcomingPrices> = observer(
  (props: IUpcomingPrices) => {
    const { building } = props;
    const { isHydrated } = nordpoolUiStore;
    const [prices, setPrices] = useState<IPricePointWithValue[]>([]);

    const updatePrices = () => {
      nordpoolUiStore
        .getUpcomingPrices(building)
        .then((points) => {
          const priceSelection =
            nordpoolUiStore.energyPriceSettings.priceSelection;
          setPrices(
            points.map((point) => {
              let value: number = -100;

              switch (priceSelection) {
                case "spot":
                  value =
                    point.spot === undefined ? 0.0 : parseFloat(point.spot);
                  break;
                case "energy":
                  value = parseFloat(point.energy);
                  break;
                case "total":
                  value = parseFloat(point.total);
                  break;
                default:
                  value = -100;
                  break;
              }

              return {
                ...point,
                time: DateTime.fromISO(point.time).toLocal(),
                value: value,
              };
            }),
          );
        })
        .catch((error) => {
          logger(error);
        });
    };

    useEffect(() => {
      if (!isHydrated) {
        return;
      }

      updatePrices();
      const timer = setInterval(() => {
        updatePrices();
      }, 60000);
      return () => clearInterval(timer);
    }, [isHydrated]);

    if (!isHydrated) {
      return null;
    }

    return <UpcomingPricesStack prices={prices} updatePrices={updatePrices} />;
  },
);

export default UpcomingPrices;
