import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";
import ActionCard from "../components/ActionCard";
import AppBar from "../components/AppBar";
import DeleteFab from "../components/DeleteFab";
import FilterCard from "../components/FilterCard";
import SmallIconButton from "../components/SmallIconButton";
import TriggerCard from "../components/TriggerCard";
import IRule, { deleteRule, saveRule } from "../services/Rule";
import ruleStore from "../stores/RuleStore";
import ErrorScreen from "./ErrorScreen";

const RuleScreen = observer(() => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/rule/:uuid?/");
  const building: string =
    params && params.building_id ? params.building_id : "";
  const uuid: string = params && params.uuid ? params.uuid : "";
  const [rule, setRule] = useState<IRule>(
    ruleStore.find(uuid) ||
      ({
        uuid: "",
        building_id: building,
        name: "",
        disabled: false,
        triggers: [],
        filters: [],
        actions: [],
      } as IRule),
  );

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (rule.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [rule]);

  const onSave = () => {
    saveRule(rule).then(() => window.history.back());
  };

  return (
    <Box>
      <AppBar
        title={rule.name || t("Add Rule")}
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Card>
            <CardContent>
              <Stack spacing={2} marginBottom={-1}>
                <TextField
                  variant="outlined"
                  type="text"
                  label={t("Name")}
                  defaultValue={rule.name}
                  onChange={(event) =>
                    setRule({ ...rule, name: event.target.value })
                  }
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rule.disabled}
                        onChange={(event) => {
                          setRule({
                            ...rule,
                            disabled: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={t("Disable Rule?")}
                  />
                </FormGroup>
              </Stack>
            </CardContent>
          </Card>
        </Container>
        {rule.triggers.length > 0 && (
          <Container maxWidth="md" sx={{ marginTop: 2 }}>
            <Typography variant="h5">{t("Triggers")}</Typography>
            <Grid container spacing={2}>
              {rule.triggers.map((trigger, index) => (
                <Grid
                  key={nanoid()}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <TriggerCard
                    building={building}
                    rule={rule.uuid}
                    trigger={trigger}
                    onUpdate={(x) => {
                      setRule({
                        ...rule,
                        triggers: rule.triggers.map((z, i) =>
                          i === index ? x : z,
                        ),
                      });
                    }}
                    remove={() => {
                      setRule({
                        ...rule,
                        triggers: rule.triggers.filter((_, i) => i !== index),
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
        {rule.filters.length > 0 && (
          <Container maxWidth="md" sx={{ marginTop: 2 }}>
            <Typography variant="h5">{t("Filters")}</Typography>
            <Grid container spacing={2}>
              {rule.filters.map((filter, index) => (
                <Grid
                  key={nanoid()}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <FilterCard
                    building={building}
                    filter={filter}
                    onUpdate={(x) => {
                      setRule({
                        ...rule,
                        filters: rule.filters.map((z, i) =>
                          i === index ? x : z,
                        ),
                      });
                    }}
                    remove={() => {
                      setRule({
                        ...rule,
                        filters: rule.filters.filter((_, i) => i !== index),
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
        {rule.actions.length > 0 && (
          <Container maxWidth="md" sx={{ marginTop: 2 }}>
            <Typography variant="h5">{t("Actions")}</Typography>
            <Grid container spacing={2}>
              {rule.actions.map((action, index) => (
                <Grid
                  key={nanoid()}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <ActionCard
                    building={building}
                    rule={rule}
                    action={action}
                    onUpdate={(x) => {
                      setRule({
                        ...rule,
                        actions: rule.actions.map((z, i) =>
                          i === index ? x : z,
                        ),
                      });
                    }}
                    remove={() => {
                      setRule({
                        ...rule,
                        actions: rule.actions.filter((_, i) => i !== index),
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
        <SpeedDial
          ariaLabel={t("Add")}
          icon={<SpeedDialIcon />}
          FabProps={{ color: "secondary" }}
          open={speedDialOpen}
          onOpen={() => setSpeedDialOpen(true)}
          onClose={() => setSpeedDialOpen(false)}
          sx={{
            zIndex: 100000,
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <SpeedDialAction
            icon={<PlayArrowIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Trigger")}
            tooltipOpen
            onClick={() => {
              setSpeedDialOpen(false);
              setRule({
                ...rule,
                triggers: [
                  ...rule.triggers,
                  {
                    trigger_class: "TraitValueTrigger",
                    traits: [],
                    type: 0,
                    value: 0,
                  },
                ],
              });
            }}
          />
          <SpeedDialAction
            icon={<FilterAltIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Filter")}
            tooltipOpen
            onClick={() => {
              setSpeedDialOpen(false);
              setRule({
                ...rule,
                filters: [
                  ...rule.filters,
                  {
                    filter_class: "TraitValueFilter",
                    only_on_first_trigger: false,
                    trait_id: "",
                    type: 0,
                    value: 0,
                  },
                ],
              });
            }}
          />
          <SpeedDialAction
            icon={<BuildCircleIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Action")}
            tooltipOpen
            onClick={() => {
              setSpeedDialOpen(false);
              setRule({
                ...rule,
                actions: [
                  ...rule.actions,
                  {
                    uuid: "",
                    action_class: "SetTraitAction",
                    delay: 0,
                    traits: [],
                    value: 0,
                    filter: 0,
                    filter_value: 0,
                  },
                ],
              });
            }}
          />
        </SpeedDial>
        {uuid && (
          <DeleteFab
            confirmText={t('Do you really want to delete rule "{{name}}"?', {
              name: rule.name,
            })}
            onDelete={() => {
              deleteRule(uuid).then(() => window.history.back());
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
});

export default RuleScreen;
