import { action, makeObservable, observable, runInAction } from "mobx";
import IState from "../../services/State";
import stateStore from "../StateStore";
import SyncedUIStore, { ISyncedUIStore } from "./SyncedUIStore";

export interface IStateUIStore extends ISyncedUIStore<IState> {
  find: (id: string) => IState | undefined;
  findIndex: (id: string) => number;
  update: (instance: IState) => [IState, boolean];
  setOrder: (ids: string[]) => void;
}

class StateUIStore extends SyncedUIStore<IState> implements IStateUIStore {
  key: string = "state";

  constructor() {
    super();
    makeObservable(this, {
      isHydrated: observable,
      instances: observable,
      update: action,
      deleteState: action,
      setOrder: action,
    });

    this.load();
  }

  find = (uuid: string): IState | undefined => {
    const instance = this.instances.find((instance) => instance.uuid === uuid);
    if (instance) {
      return instance;
    }
    const storeInstance = stateStore.find(uuid);
    if (storeInstance) {
      this.instances.push(storeInstance);
    }
    return storeInstance;
  };

  findIndex = (uuid: string): number =>
    this.instances.findIndex((instance) => instance.uuid === uuid);

  update = (instance: IState): [IState, boolean] => {
    const instanceIndex = this.findIndex(instance.uuid);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as IState));
      return [instance as IState, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = instance as IState;
    });
    return [instance as IState, false];
  };

  deleteState = (instance: IState) => {
    runInAction(() => {
      this.instances = this.instances.filter(
        (state) => state.uuid !== instance.uuid,
      );
    });
  };

  setOrder = (uuids: string[]) => {
    uuids.forEach((uuid) => {
      this.find(uuid);
    });
    runInAction(() => {
      this.instances.sort((a, b) =>
        uuids.indexOf(a.uuid) < uuids.indexOf(b.uuid) ? -1 : 1,
      );
    });
  };
}

const stateUIStore = new StateUIStore();
export default stateUIStore;
