import { action, makeObservable, observable, runInAction } from "mobx";
import SyncedUIStore, { ISyncedUIStore } from "./SyncedUIStore";

export interface ISingleGroupUIStore {
  uuid: string;
  hiddenTraits: string[];
  traitOrder: string[];
}

export interface IGroupUIStore extends ISyncedUIStore<ISingleGroupUIStore> {
  find: (uuid: string) => ISingleGroupUIStore;
  findIndex: (uuid: string) => number;
  update: (instance: ISingleGroupUIStore) => [ISingleGroupUIStore, boolean];
  setOrder: (uuids: string[]) => void;
  setTraitOrder: (uuid: string, newOrder: string[]) => void;
  hideTrait: (uuid: string, trait: string) => void;
  showTrait: (uuid: string, trait: string) => void;
}

class GroupUIStore
  extends SyncedUIStore<ISingleGroupUIStore>
  implements IGroupUIStore
{
  key: string = "group";

  constructor() {
    super();
    makeObservable(this, {
      isHydrated: observable,
      instances: observable,
      find: action,
      update: action,
      setOrder: action,
      setTraitOrder: action,
      hideTrait: action,
      showTrait: action,
    });

    this.load();
  }

  find = (uuid: string): ISingleGroupUIStore => {
    const instance = this.instances.find((group) => group.uuid === uuid);
    if (!instance) {
      const newInstance = {
        uuid: uuid,
        hiddenTraits: [],
        traitOrder: [],
      } as ISingleGroupUIStore;
      this.instances.push(newInstance);
      return newInstance;
    }
    return instance;
  };

  findIndex = (uuid: string): number =>
    this.instances.findIndex((instance) => instance.uuid === uuid);

  update = (instance: ISingleGroupUIStore): [ISingleGroupUIStore, boolean] => {
    const instanceIndex = this.findIndex(instance.uuid);
    if (instanceIndex === -1) {
      // If instance wasn't found -> push to end
      runInAction(() => this.instances.push(instance as ISingleGroupUIStore));
      return [instance as ISingleGroupUIStore, true];
    }
    // Otherwise update the instance
    runInAction(() => {
      this.instances[instanceIndex] = instance as ISingleGroupUIStore;
    });
    return [instance as ISingleGroupUIStore, false];
  };

  setOrder = (uuids: string[]) => {
    runInAction(() => {
      this.instances.sort((a, b) =>
        uuids.indexOf(a.uuid) < uuids.indexOf(b.uuid) ? -1 : 1,
      );
    });
  };

  setTraitOrder = (uuid: string, newOrder: string[]) => {
    const instance = this.find(uuid);
    instance.traitOrder = newOrder;
    this.update(instance);
  };

  hideTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = [...instance.hiddenTraits, trait];
    this.update(instance);
  };

  showTrait = (uuid: string, trait: string) => {
    const instance = this.find(uuid);
    instance.hiddenTraits = instance.hiddenTraits.filter((t) => t !== trait);
    this.update(instance);
  };
}

const groupUIStore = new GroupUIStore();
export default groupUIStore;
