import getAuthenticatedInstance from "./authenticated";
import { BuildingChildInstance } from "./base";
import { ILogMessage } from "./Log";

export interface ITrigger {
  trigger_class:
    | "StateTrigger"
    | "TraitValueTrigger"
    | "TimeTrigger"
    | "ActionTrigger"
    | "WebhookTrigger";
}

export interface IStateTrigger extends ITrigger {
  trigger_class: "StateTrigger";
  state: string;
}

export interface ITraitValueTrigger extends ITrigger {
  trigger_class: "TraitValueTrigger";
  traits: string[];
  type: 0 | 1;
  value: number;
}

export interface ITimeTrigger extends ITrigger {
  trigger_class: "TimeTrigger";
  cron: string;
}

export interface IActionTrigger extends ITrigger {
  trigger_class: "ActionTrigger";
  traits: string[];
  actions: string;
}

export interface IWebhookTrigger extends ITrigger {
  trigger_class: "WebhookTrigger";
  uuid?: string;
}

export interface IFilter {
  filter_class: "StateFilter" | "TraitValueFilter" | "TimeFilter";
  only_on_first_trigger: boolean;
}

export interface IStateFilter extends IFilter {
  filter_class: "StateFilter";
  state: string;
}

export interface ITraitValueFilter extends IFilter {
  filter_class: "TraitValueFilter";
  trait_id: string;
  type: 0 | 1;
  value: number;
}

export interface ITimeFilter extends IFilter {
  filter_class: "TimeFilter";
  cron: string;
}

export interface IAction {
  uuid: string;
  action_class:
    | "SetTraitAction"
    | "VaryTraitAction"
    | "ScriptAction"
    | "NotificationAction";
  delay: number;
}

export interface ISetTraitAction extends IAction {
  action_class: "SetTraitAction";
  traits: string[];
  value: number;
  filter: number;
  filter_value: number;
}

export interface IVaryTraitAction extends IAction {
  action_class: "VaryTraitAction";
  traits: string[];
  value: number;
}

export interface IScriptAction extends IAction {
  action_class: "ScriptAction";
  script: string;
}

export type TNotificationActionLevel = "info" | "success" | "warning" | "error";

export interface INotificationAction extends IAction {
  action_class: "NotificationAction";
  message: string;
  level: TNotificationActionLevel;
}

interface IRule extends BuildingChildInstance {
  name: string;
  disabled: boolean;
  triggers: (
    | IStateTrigger
    | ITraitValueTrigger
    | ITimeTrigger
    | IActionTrigger
    | IWebhookTrigger
  )[];
  filters: (IStateFilter | ITraitValueFilter | ITimeFilter)[];
  actions: (
    | ISetTraitAction
    | IVaryTraitAction
    | IScriptAction
    | INotificationAction
  )[];
}

export const saveRule = async (rule: IRule): Promise<IRule> => {
  if (rule.uuid.length > 0) {
    const response = await getAuthenticatedInstance().patch(
      `/rule/${rule.uuid}/`,
      rule,
    );
    return response.data as IRule;
  }
  const response = await getAuthenticatedInstance().post("/rule/", rule);
  return response.data as IRule;
};

export const deleteRule = async (uuid: string): Promise<IRule> => {
  const response = await getAuthenticatedInstance().delete(`/rule/${uuid}/`);
  return response.data as IRule;
};

export const runAction = async (
  uuid: string,
  action_uuid: string,
): Promise<ILogMessage[]> => {
  const response = await getAuthenticatedInstance().get(
    `/rule/${uuid}/action/${action_uuid}/run/`,
  );
  return response.data as ILogMessage[];
};

export const runUnattachedAction = async (
  building_id: string,
  action:
    | ISetTraitAction
    | IVaryTraitAction
    | IScriptAction
    | INotificationAction,
): Promise<ILogMessage[]> => {
  const response = await getAuthenticatedInstance().post(
    `/rule/action/run/?building_id=${building_id}`,
    action,
  );
  return response.data as ILogMessage[];
};

export default IRule;
