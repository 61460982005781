import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import nordpoolUiStore, {
  TPriceSelection,
} from "../../../stores/UI/plugin/nordpool/NordpoolUIStore";
import { UpcomingPricesStack } from "./UpcomingPrices";

const PriceSelection = observer(() => {
  const { t } = useTranslation();
  const { priceSelection } = nordpoolUiStore.energyPriceSettings;

  return (
    <FormControl fullWidth>
      <InputLabel>{t("Price source")}</InputLabel>
      <Select
        label={t("Price source")}
        value={priceSelection}
        onChange={(event) =>
          nordpoolUiStore.setPriceSelection(
            event.target.value as TPriceSelection,
          )
        }
      >
        <MenuItem value="spot">
          {t("Spot-price (excluding VAT, margin etc)")}
        </MenuItem>
        <MenuItem value="energy">
          {t("Energy price (including taxes etc)")}
        </MenuItem>
        <MenuItem value="total">
          {t("Total price (including energy, distribution tariff, etc)")}
        </MenuItem>
      </Select>
    </FormControl>
  );
});

const PriceForecastSelection = observer(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showForecast } = nordpoolUiStore.energyPriceSettings;

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Switch
            checked={showForecast}
            onChange={() => {
              nordpoolUiStore.toggleShowForecast();
            }}
          />
        }
        label={t("Show energy price forecasts?")}
      />
      <FormHelperText component="div">
        {t(
          "If used, shows forecasted energy prices in addition to the confirmed prices, if available.",
        )}
        {showForecast && (
          <Typography
            variant="inherit"
            sx={{ background: theme.palette.secondary.light }}
          >
            {t(
              "The forecasted prices will be shown with different background color.",
            )}
          </Typography>
        )}
      </FormHelperText>
    </FormControl>
  );
});

const PriceRangeSelection = observer(() => {
  const { t } = useTranslation();
  const minDistance = 4;
  const { priceRange, usePriceRange } = nordpoolUiStore.energyPriceSettings;
  const [min, max] = priceRange || [2, 10];
  const numberOfPointsInExample = 24;
  const exampleStep = (max - min) / numberOfPointsInExample;
  const now = DateTime.now();

  return (
    <Stack direction="column" spacing={2}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={usePriceRange}
              onChange={() => {
                nordpoolUiStore.toggleUsePriceRange();
              }}
            />
          }
          label={t("Use custom price range to color the values?")}
        />
        <FormHelperText>
          {t(
            "If not used, the colors will be determined based on the minimum and maximum of upcoming hours.",
          )}
        </FormHelperText>
      </FormControl>
      {usePriceRange && (
        <>
          <Typography>
            {t("Price range to use when coloring the prices")}
          </Typography>
          <Slider
            value={[min, max]}
            min={0}
            max={50}
            onChange={(_, value, activeThumb) => {
              if (!Array.isArray(value)) {
                return;
              }

              if (value[1] - value[0] < minDistance) {
                if (activeThumb === 0) {
                  const clamped = Math.min(value[0], 100 - minDistance);
                  nordpoolUiStore.setPriceRange(clamped, clamped + minDistance);
                } else {
                  const clamped = Math.max(value[1], minDistance);
                  nordpoolUiStore.setPriceRange(clamped - minDistance, clamped);
                }
              } else {
                nordpoolUiStore.setPriceRange(value[0], value[1]);
              }
            }}
            valueLabelDisplay="auto"
            disableSwap
          />
          <Typography lineHeight={1} paddingTop={1} paddingBottom={0}>
            {t("Example of the price range")}
          </Typography>
          <UpcomingPricesStack
            prices={Array(numberOfPointsInExample + 1)
              .fill(0)
              .map((_, i) => {
                const exampleNow = now.plus({ hours: i }).startOf("hour");

                return {
                  time: exampleNow,
                  spot: (i * exampleStep + min).toFixed(3),
                  energy: (i * exampleStep + min).toFixed(3),
                  distribution: (i * exampleStep + min).toFixed(3),
                  total: (i * exampleStep + min).toFixed(3),
                  value: i * exampleStep + min,
                  forecast: now.day === exampleNow.day ? false : "day",
                };
              })}
          />
        </>
      )}
    </Stack>
  );
});

const UpcomingPricesSettings = observer(() => {
  const { t } = useTranslation();
  const { showEnergyPricesOnTop } = nordpoolUiStore.energyPriceSettings;

  return (
    <Stack direction="column" spacing={2}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={showEnergyPricesOnTop}
              onChange={() => {
                nordpoolUiStore.toggleShowEnergyPricesOnTop();
              }}
            />
          }
          label={t("Show energy prices on top on building pages?")}
        />
      </FormControl>
      {showEnergyPricesOnTop && <PriceSelection />}
      {showEnergyPricesOnTop && <PriceForecastSelection />}
      {showEnergyPricesOnTop && <PriceRangeSelection />}
    </Stack>
  );
});

export default UpcomingPricesSettings;
